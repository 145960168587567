import React from "react";
import Aos from "aos";
import "aos/dist/aos.css";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import {
  RiNumber1,
  RiNumber2,
  RiNumber3,
  RiNumber4,
  RiNumber5,
  RiNumber6,
} from "react-icons/ri";

const How = () => {
  Aos.init({ duration: 1000 });
  return (
    <div className="how mb-12" id="how">
      <div className="text-4xl text-center font-extrabold mt-7 mb-4">
        <h1>How We Do</h1>
      </div>
      {/* Stepper Section */}
      <VerticalTimeline lineColor="#7a8204">
        {/* 1st timeline */}
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(238, 173, 76)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(238, 173, 76)" }}
          iconStyle={{ background: "rgb(238, 173, 76)", color: "#fff" }}
          icon={<RiNumber1 />}
        >
          <h3 className="vertical-timeline-element-title text-3xl font-extrabold">
            Research
          </h3>
          <p>
            On the basis of demographics ,Trends ,changing Environments ,
            Technological advancements , Needs , Lifestyle we research on the
            project.
          </p>
        </VerticalTimelineElement>

        {/* 2nd Timeline */}
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(0, 140, 69)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(0, 140, 69)" }}
          iconStyle={{ background: "rgb(0, 140, 69)", color: "#ffffff" }}
          icon={<RiNumber2 />}
        >
          <h3 className="vertical-timeline-element-title text-3xl font-extrabold">
            Choose
          </h3>
          <p>
            On the basis of Research recommendation purely based on viability
            and sustainability we choose the projects from the market and
            forward it for analysis before investment.
          </p>
        </VerticalTimelineElement>

        {/* 3rd Timeline */}
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(238, 173, 76)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(238, 173, 76)" }}
          iconStyle={{ background: "rgb(238, 173, 76)", color: "#fff" }}
          icon={<RiNumber3 />}
        >
          <h3 className="vertical-timeline-element-title text-3xl font-extrabold">
            Analyze
          </h3>
          <p>
          Financial and Economical Analysis using different tools and data for its recommendation to invest  i.e.  set the KPI (key performance indicator )
          </p>
        </VerticalTimelineElement>

        {/* 4th timeline */}
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(0, 140, 69)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(0, 140, 69)" }}
          iconStyle={{ background: "rgb(0, 140, 69)", color: "#ffffff" }}
          icon={<RiNumber4 />}
        >
          <h3 className="vertical-timeline-element-title text-3xl font-extrabold">
            Invest
          </h3>
          <p>
          The standard process for investment decision is done on the basic of  recommendation after analyzing what it is mainly based on , its sustainable value addition. Further findings leads to design  the quality ratio and arrangements.         
          </p>
        </VerticalTimelineElement>

        {/* 5th Timeline */}
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(238, 173, 76)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(238, 173, 76)" }}
          iconStyle={{ background: "rgb(238, 173, 76)", color: "#fff" }}
          icon={<RiNumber5 />}
        >
          <h3 className="vertical-timeline-element-title text-3xl font-extrabold">
            Operate
          </h3>
          <p>
            Planned operational Modality needs to be monitored carefully for
            proper execution . We intend to meet it through technology based on
            MIS . Proper involvement in the operation is done to get the desired
            result of value and profit maximization .
          </p>
        </VerticalTimelineElement>

        {/* 6th timeline */}
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(0, 140, 69)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(0, 140, 69)" }}
          iconStyle={{ background: "rgb(0, 140, 69)", color: "#ffffff" }}
          icon={<RiNumber6 />}
        >
          <h3 className="vertical-timeline-element-title text-3xl font-extrabold">
            Encash
          </h3>
          <p>
            After the achievement of desired return . A plan is made to exit
            from the project .
          </p>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  );
};

export default How;
