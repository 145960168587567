import React from "react";
import "./Footer.css";
import { BsFacebook } from "react-icons/bs";
import { AiFillTwitterCircle, AiOutlineGithub } from "react-icons/ai";
import { Link } from 'react-scroll';

const Footer = () => {
  return (
    <div>
      <footer class="text-center bgFooter text-white">
        <div class="max-w-screen-xl px-4 py-6 mx-auto sm:px-6 lg:px-8">
          <div class="max-w-3xl mx-auto space-y-6">
            {/* Logo Here */}
            <div className="flex justify-center items-center">
              <img
                src={process.env.PUBLIC_URL + "/images/vjLogo.png"}
                alt="logo"
                className="h-16 w-13"
              />
            </div>

            <nav className="hidden md:block relative flex flex-wrap justify-center gap-10 p-5 text-sm font-bold border-4 border-white rounded-xl">
              <Link
              to="hero" smooth={true}
                className="hover:text-yellow-400 cursor-pointer"
              >
                Home
              </Link>
              <Link
              to="who" smooth={true} spy={true}
                className="mx-4 hover:text-yellow-400 cursor-pointer"
              >
                Who
              </Link>
              <Link
              to="what" smooth={true} spy={true}
                className="hover:text-yellow-400 cursor-pointer"
              >
                What
              </Link>
              <Link
              to="faq" smooth={true} spy={true}
                className="mx-4 hover:text-yellow-400 cursor-pointer"
              >
                Faq
              </Link>
              <Link
              to="how" smooth={true} spy={true}
                className="hover:text-yellow-400 cursor-pointer"
              >
                How
              </Link>
              <Link
              to="deals" smooth={true} spy={true}
                className="mx-4 hover:text-yellow-400 cursor-pointer"
              >
                Deals
              </Link>
              <Link
              to="contact" smooth={true}
                className="hover:text-yellow-400 cursor-pointer"
              >
                Contact
              </Link>

            </nav>

            <div class="flex justify-center space-x-6">
              {/* Social Media Icons */}
              <BsFacebook size={30} />
              <AiFillTwitterCircle size={35} />
            </div>

            {/* CopyRight */}
            <p class="text-xs text-gray-300">
              Copyright © 2022 Vj Investment.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
