import React, { useEffect, useState, CSSProperties } from 'react'
import Hero from '../Components/HeroSection/Hero'
import Navbar from '../Components/Navigation/Navbar';
import Who from '../Components/WhoSection/Who';
import What from '../Components/whatSection/What';
import How from '../Components/HowSection/How';
import Slider from '../Components/sliderSection/Slider';
import Contact from '../Components/contactSection/Contact';
import Footer from '../Components/footerSection/Footer';
import HashLoader from "react-spinners/HashLoader";
import NewChart from '../Components/ChartSection/NewChart'



const App = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2000);
  })

  return (
    <div>
    {loading ? <>  <HashLoader size={150} color={"green"} className='block m-auto mt-48 text-center'/>
</> : <>
        <Navbar />
        <Hero />
        <Who />
        <What />
        <How />
        <NewChart />
        <Slider />
        <Contact />
        <Footer />
        </>}
        

    </div>
  )
}

export default App