import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const SliderCarousel = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 1000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }
  return (
    <div className="bg-white/20 overflow-hidden" id="deals">
      <div className="flex justify-center mb-12 mt-32">
        <heading className="text-4xl font-extrabold">
          Sectors we Deal
        </heading>
      </div>
      <Slider {...settings} className='mb-24'>
        {/* first */}

        <div>
          <div className="bg-white mx-8 w-18 h-40 flex flex-col justify-center items-center border border-gray-200 shadow-lg rounded-xl">
            <img
              src={process.env.PUBLIC_URL + "/images/financial.png"}
              alt="financial"
              width="60"
              height="75"
            />
            <p className="mt-4 text-gray-900 text-xs lg:text-lg">Financial</p>
          </div>
        </div>

        <div>
          <div className="bg-white mx-8 w-18 h-40 flex flex-col justify-center items-center border border-gray-200 shadow-lg rounded-xl">
            <img
              src={process.env.PUBLIC_URL + "/images/insurance.png"}
              alt="Insurance"
              width="60"
              height="75"
            />
            <p className="mt-4 text-gray-900 text-xs lg:text-lg">Insurance</p>
          </div>
        </div>

        <div>
          <div className="bg-white mx-8 w-18 h-40 flex flex-col justify-center items-center border border-gray-200 shadow-lg rounded-xl">
            <img
              src={process.env.PUBLIC_URL + "/images/hydroPower.png"}
              alt="Hydropower"
              width="60"
              height="75"
            />
            <p className="mt-4 text-gray-900 text-xs lg:text-lg">Hydropower</p>
          </div>
        </div>

        <div>
          <div className="bg-white mx-8 w-18 h-40 flex flex-col justify-center items-center border border-gray-200 shadow-lg rounded-xl">
            <img
              src={process.env.PUBLIC_URL + "/images/tourism.png"}
              alt="Tourism"
              width="60"
              height="75"
            />
            <p className="mt-4 text-gray-900 text-xs lg:text-lg">Tourism</p>
          </div>
        </div>

        <div>
          <div className="bg-white mx-8 w-18 h-40 flex flex-col justify-center items-center border border-gray-200 shadow-lg rounded-xl">
            <img
              src={process.env.PUBLIC_URL + "/images/agriculture.png"}
              width="60"
              height="75"
              alt="Agriculture"
            />
            <p className="mt-4 text-gray-900 text-xs lg:text-lg">Agriculture</p>
          </div>
        </div>

        <div>
          <div className="bg-white mx-8 w-18 h-40 flex flex-col justify-center items-center border border-gray-200 shadow-lg rounded-xl">
            <img
              src={process.env.PUBLIC_URL + "/images/industry.png"}
              alt="Industry"
              width="60"
              height="75"
            />
            <p className="mt-4 text-gray-900 text-xs lg:text-lg">Industry</p>
          </div>
        </div>

        <div>
          <div className="bg-white mb-5 mx-8 w-18 h-40 flex flex-col justify-center items-center border border-gray-200 shadow-lg rounded-xl">
            <img
              src={process.env.PUBLIC_URL + "/images/startup.png"}
              alt="Startup"
              width="60"
              height="75"
            />
            <p className="mt-4 text-gray-900 text-xs lg:text-lg">Startup</p>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default SliderCarousel;
