import React, { useState } from "react";
import "./Hero.css";
import video from "../../Assets/video/heroVideo.mp4";
import { motion } from "framer-motion";
import { useEffect } from "react";

const Hero = () => {
  const [initial, setIntitial] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIntitial(false);
    }, 5000);
  });

  const AnimateTrialGroup1 = {
    initial: { y: 400 },
    animate: {
      y: -300,
      // backgroundColor: "#000",
      // boxShadow: "10px 10px 0 rgba(0, 0, 0, 0.2)",
      position: "fixed",
      transitionEnd: {
        display: "none",
      },
    },
    transition: { duration: 6, repeat: Infinity },
  };
  const AnimateTrialGroup2 = {
    initial: { y: 400 },
    animate: {
      y: -300,
      // backgroundColor: "#000",
      // boxShadow: "10px 10px 0 rgba(0, 0, 0, 0.2)",
      position: "fixed",
      transitionEnd: {
        display: "none",
      },
    },
    transition: { duration: 9, repeat: Infinity },
  };
  const AnimateTrialGroup3 = {
    initial: { y: 400 },
    animate: {
      y: -300,
      // backgroundColor: "#000",
      // boxShadow: "10px 10px 0 rgba(0, 0, 0, 0.2)",
      position: "fixed",
      transitionEnd: {
        display: "none",
      },
    },
    transition: { duration: 6, repeat: Infinity },
  };
  const AnimateTrialGroup4 = {
    initial: { y: 400 },
    animate: {
      y: -300,
      // backgroundColor: "#000",
      // boxShadow: "10px 10px 0 rgba(0, 0, 0, 0.2)",
      position: "fixed",
      transitionEnd: {
        display: "none",
      },
    },
    transition: { duration: 9, repeat: Infinity },
  };
  const AnimateTrialGroup5 = {
    initial: { y: 400 },
    animate: {
      y: -300,
      // backgroundColor: "#000",
      // boxShadow: "10px 10px 0 rgba(0, 0, 0, 0.2)",
      position: "fixed",
      transitionEnd: {
        display: "none",
      },
    },
    transition: { duration: 8, repeat: Infinity },
  };
  const AnimateTrialGroup6 = {
    initial: { y: 400 },
    animate: {
      y: -300,
      // backgroundColor: "#000",
      // boxShadow: "10px 10px 0 rgba(0, 0, 0, 0.2)",
      position: "fixed",
      transitionEnd: {
        display: "none",
      },
    },
    transition: { duration: 4, repeat: Infinity },
  };
  const AnimateTrialGroup7 = {
    initial: { y: 400 },
    animate: {
      y: -300,
      // backgroundColor: "#000",
      // boxShadow: "10px 10px 0 rgba(0, 0, 0, 0.2)",
      position: "fixed",
      transitionEnd: {
        display: "none",
      },
    },
    transition: { duration: 5, repeat: Infinity },
  };
  const AnimateTrialGroup8 = {
    initial: { y: 390 },
    animate: {
      y: -300,
      // backgroundColor: "#000",
      // boxShadow: "10px 10px 0 rgba(0, 0, 0, 0.2)",
      position: "fixed",
      transitionEnd: {
        display: "none",
      },
    },
    transition: { duration: 6, repeat: Infinity },
  };
  const AnimateTrialGroup9 = {
    initial: { y: 400 },
    animate: {
      y: -300,
      // backgroundColor: "#000",
      // boxShadow: "10px 10px 0 rgba(0, 0, 0, 0.2)",
      position: "fixed",
      transitionEnd: {
        display: "none",
      },
    },
    transition: { duration: 4, repeat: Infinity },
  };
  const AnimateTrialGroup10 = {
    initial: { y: 380 },
    animate: {
      y: -300,
      // backgroundColor: "#000",
      // boxShadow: "10px 10px 0 rgba(0, 0, 0, 0.2)",
      position: "fixed",
      transitionEnd: {
        display: "none",
      },
    },
    transition: { duration: 5, repeat: Infinity },
  };

  const AnimateTrialGroup11 = {
    initial: { y: 410 },
    animate: {
      y: -300,
      // backgroundColor: "#000",
      // boxShadow: "10px 10px 0 rgba(0, 0, 0, 0.2)",
      position: "fixed",
      transitionEnd: {
        display: "none",
      },
    },
    transition: { duration: 9, repeat: Infinity },
  };

  return (
    <div className="main" id="hero">
      <video src={video} autoPlay loop muted />
      <div className="overlay"></div>
      <section class="content">
        <div class="max-w-screen-xl px-4 py-32 mx-auto lg:items-center lg:justify-center lg:flex">
          <div className="max-w-3xl mx-auto flex justify-center items-center">
            <div className="sm:w-96 w-64">
              <img
                src={process.env.PUBLIC_URL + "/images/logoBgRemove.png"}
                alt="Vj_investment"
              />
            </div>
            {/* <h1 class="text-3xl font-extrabold text-transparent sm:text-5xl bg-clip-text bg-gradient-to-r from-yellow-500 via-white to-green-500">
      Nepal's Top
        <span class="sm:block">
          Investment Company
        </span>
      </h1>

      <p class="max-w-xl mx-auto mt-4 sm:leading-relaxed sm:text-xl">
      Vj investment isn't owned by shareholders. It's owned by the people
          who invest in our funds.      </p>

      <div class="flex flex-wrap justify-center gap-4 mt-8">
        <Link 
          to="contact" smooth={true} spy={true} offset={-74} activeClassName="active"
         className="block w-full px-12 py-3 text-sm font-medium  text-white bg-yellow-600  rounded sm:w-auto active:text-opacity-75 hover:backdrop-blur-md hover:bg-white/30 hover:text-black cursor-pointer">
          Get Started
        </Link>
      </div> */}

            {initial ? (
              <>
                 <motion.div
                  {...AnimateTrialGroup1}
                  className="absolute top-0 right-22 lg:block bg- h-20 w-20"
                >
                   <div className="flex justify-center items-center border-2 border-[#60B494]">
                    <h1 className="text-[#60B494] font-bold text-xl">ROI</h1>
                  </div>
                </motion.div>

                <motion.div
                  {...AnimateTrialGroup2}
                  className="absolute hidden top-0 left-12  lg:block h-28 w-28"
                >
                   <div className="flex justify-center items-center border-2 border-red-500">
                    <h1 className="text-red-500 font-bold text-md">EBITDA</h1>
                  </div>
                </motion.div>

                <motion.div
                  {...AnimateTrialGroup3}
                  className="absolute top-0 left-4 hidden lg:block  h-28 w-28"
                >
                   <div className="flex justify-center items-center border-2 border-[#74BC04]">
                    <h1 className="text-[#74BC04] font-bold text-lg">ROCA</h1>
                  </div>
                </motion.div>

                <motion.div
                  {...AnimateTrialGroup4}
                  className="absolute top-0 right-0 hidden lg:block h-24 w-20"
                >
                  <div className="flex justify-center items-center border-2 border-[#F6DB23]">
                    <h1 className="text-[#F6DB23] font-bold text-xl">KPI</h1>
                  </div>
                </motion.div>

                <motion.div
                  {...AnimateTrialGroup5}
                  className="absolute top-0 right-80 hidden lg:block bg- h-20 w-20"
                >
                  <div className="flex justify-center items-center border-2 border-[#045381]">
                    <h1 className="text-[#045381] font-bold text-2xl">GP</h1>
                  </div>
                </motion.div>

                <motion.div
                  {...AnimateTrialGroup6}
                  className="absolute top-0 left-80 hidden lg:block h-20 w-20  "
                >
                  <div className="flex justify-center items-center border-2 border-purple-400">
                    <h1 className="text-purple-400 font-bold text-2xl ">NP</h1>
                  </div>
                </motion.div>
              </>
            ) : (
              <>
                <motion.div
                  {...AnimateTrialGroup7}
                  className="absolute top-0 right-48 lg:block  h-24 w-24"
                >
                   <div className="flex justify-center items-center border-2 border-[#E1EC65]">
                    <h1 className="text-[#E1EC65] font-bold text-xl">EBIT</h1>
                  </div>
                </motion.div>

                <motion.div
                  {...AnimateTrialGroup8}
                  className="absolute top-0 right-10 lg:block h-24 w-24"
                >
                   <div className="flex justify-center items-center border-2 border-[#B3795A]">
                    <h1 className="text-[#B3795A] font-bold text-2xl">DPS</h1>
                  </div>
                </motion.div>

                <motion.div
                  {...AnimateTrialGroup9}
                  className="absolute top-0 left-0 lg:block bg- h-20 w-20"
                >
                   <div className="flex justify-center items-center border-2 border-[#FC9404]">
                    <h1 className="text-[#FC9404] font-bold text-2xl">PE</h1>
                  </div>
                </motion.div>

                <motion.div
                  {...AnimateTrialGroup10}
                  className="absolute top-0 left-60 hidden lg:block h-40 w-40"
                >
                   <div className="flex justify-center items-center border-2 border-[#98BDDE]">
                    <h1 className="text-[#98BDDE] font-bold text-lg ">MARKETCAP</h1>
                  </div>
                </motion.div>

                <motion.div
                  {...AnimateTrialGroup1}
                  className="absolute top-0 right-22 lg:block bg- h-20 w-20"
                >
                   <div className="flex justify-center items-center border-2 border-[#60B494]">
                    <h1 className="text-[#60B494] font-bold text-xl">ROI</h1>
                  </div>
                </motion.div>

                <motion.div
                  {...AnimateTrialGroup11}
                  className="absolute top-0 left-42 hidden lg:block h-28 w-24 "
                >
                   <div className="flex justify-center items-center border-2 border-[#FA5F13]">
                    <h1 className="text-[#FA5F13] font-bold text-md">EBITDA</h1>
                  </div>
                </motion.div>

                <motion.div
                  {...AnimateTrialGroup3}
                  className="absolute top-0 left-4 hidden lg:block  h-28 w-28"
                >
                   <div className="flex justify-center items-center border-2 border-[#74BC04]">
                    <h1 className="text-[#74BC04] font-bold text-lg">ROCA</h1>
                  </div>
                </motion.div>

                <motion.div
                  {...AnimateTrialGroup4}
                  className="absolute top-0 right-0 hidden lg:block h-24 w-20"
                >
                  <div className="flex justify-center items-center border-2 border-[#F6DB23]">
                    <h1 className="text-[#F6DB23] font-bold text-xl">KPI</h1>
                  </div>
                </motion.div>

                <motion.div
                  {...AnimateTrialGroup5}
                  className="absolute top-0 right-80 hidden lg:block bg- h-20 w-20"
                >
                  <div className="flex justify-center items-center border-2 border-[#045381]">
                    <h1 className="text-[#045381] font-bold text-2xl">GP</h1>
                  </div>
                </motion.div>

                <motion.div
                  {...AnimateTrialGroup6}
                  className="absolute top-0 left-80 hidden lg:block h-20 w-20  "
                >
                  <div className="flex justify-center items-center border-2 border-purple-400">
                    <h1 className="text-purple-400 font-bold text-2xl ">NP</h1>
                  </div>
                </motion.div>




                 {/* <motion.div
                  {...AnimateTrialGroup1}
                  className="absolute top-0 right-20 hidden lg:block h-20 w-20"
                >
                  <div className="flex justify-center items-center h-full w-full">
                    <h1 className="text-red-400 font-bold text-2xl border-2 border-red-400">NP</h1>
                  </div>
                </motion.div>
                <motion.div
                  {...AnimateTrialGroup2}
                  className="absolute top-0 left-18 hidden lg:block h-20 w-20"
                >
                  <div className="flex justify-center items-center h-full w-full">
                    <h1 className="text-green-400 font-bold text-2xl border-2 border-green-400">NSDFDP</h1>
                  </div>
                </motion.div> 
                <motion.div
                  {...AnimateTrialGroup3}
                  className="absolute top-0 right-8 hidden lg:block h-20 w-20"
                >
                  <div className="flex justify-center items-center h-full w-full">
                    <h1 className="text-yellow-400 font-bold text-2xl border-2 border-yellow-400">NAAP</h1>
                  </div>
                </motion.div> 
                <motion.div
                  {...AnimateTrialGroup4}
                  className="absolute top-0 left-8 hidden lg:block h-20 w-20"
                >
                  <div className="flex justify-center items-center h-full w-full">
                    <h1 className="text-orange-400 font-bold text-2xl border-2 border-orange-400">NDDFP</h1>
                  </div>
                </motion.div> 
                <motion.div
                  {...AnimateTrialGroup5}
                  className="absolute top-0 right-80 hidden lg:block h-20 w-20"
                >
                  <div className="flex justify-center items-center h-full w-full">
                    <h1 className="text-pink-400 font-bold text-2xl border-2 border-pink-400">NPAA</h1>
                  </div>
                </motion.div> 
                <motion.div
                  {...AnimateTrialGroup6}
                  className="absolute top-0 left-80 hidden lg:block h-20 w-20"
                >
                  <div className="flex justify-center items-center h-full w-full">
                    <h1 className="text-blue-400 font-bold text-2xl border-2 border-blue-400">NFFGDP</h1>
                  </div>
                </motion.div>  */}
              </>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Hero;
