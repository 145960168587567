import React from "react";

import "./What.css";

const What = () => {
  return (
    <div id="what">
      <section class="text-black bgWhat mt-4 mb-12">
        <div class="max-w-screen-xl px-4 py-16 mx-auto sm:px-6  lg:px-8">
          <div class="max-w-lg mx-auto text-center">
            <h2 class="text-3xl font-bold sm:text-4xl">Our Investments</h2>
          </div>
          {/* All the Cards  */}
          <div class="grid grid-cols-1 place-items-center gap-8 mt-8 md:grid-cols-2 lg:grid-cols-2">
            {/* card 1 */}
            <a
              class=" bg-white block text-center py-7 px-4 transition border border-white shadow-xl rounded-xl hover:shadow-yellow-500/20 hover:border-yellow-500/10"
              href="/services/"
            >
              <div className="flex items-center justify-center">
                {/* Icon here */}
                <img
                  src={process.env.PUBLIC_URL + "/images/suryaLogo.png"}
                  alt="suryaLifeLogo"
                  width="80"
                  height="50"
                />
              </div>

              <h3 class="capitalize mt-4 text-xl font-bold">
                Surya Life Insurance
              </h3>

              <p class="mt-1 text-sm text-gray-400">
                Surya Life Insurance Company Ltd. Has Been Established And
                Registered Under Company Act 2063 B.S.(Regd. No. 1006/063-64)
                And Insurance Act 2049 As A Public Limited Company.
                The authorized capital of the company is NRs 300 crore and Paid up Capital of the company is NRs 215.51 crores. The contribution ratio of promoter and public is 70% and 30% respectively.
              </p>
            </a>
            {/* card 2 */}
            <a
              class=" bg-white block text-center py-7 px-4 transition border border-white shadow-xl rounded-xl hover:shadow-yellow-500/20 hover:border-yellow-500/10"
              href="/services/"
            >
              <div className="flex items-center justify-center">
                {/* Icon here */}
                <img
                  src={process.env.PUBLIC_URL + "/images/factory.png"}
                  alt="suryaLifeLogo"
                  width="80"
                  height="50"
                />
              </div>

              <h3 class="capitalize mt-4 text-xl font-bold">Big Industries</h3>

              <p class="mt-1 text-sm text-gray-400">
                Business Interest Group of Industries Ltd. – BIG Industries was
                established in February 2021 to serve society through healthy
                and hygienic agro products and promote entrepreneurship culture
                by investing in diversified sectors. The company has envisioned
                integrating, professionals, experts, farmers, and the public
                through inclusion in ownership and community empowerment.
              </p>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default What;
