import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import "./Contact.css";

const Contact = () => {
  const handleSubmit = async (values) => {
    // alert(values);
  };
  return (
    <div className="bgContact" id="contact">
      <section class="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8 sm:py-14">
        <div className="text-center mb-4">
          <heading className="font-extrabold text-3xl text-black uppercase tracking-widest">
            Contact Us
          </heading>
        </div>
        {/* Contact Form*/}
        <div className="">
          <Formik
            initialValues={{
              name: "",
              email: "",
              phone: "",
              message: "",
            }}
            validationSchema={yup.object().shape({
              name: yup.string().required("Please enter your name"),
              email: yup
                .string()
                .required("Please enter your email")
                .email("Please enter a valid email"),
              message: yup.string().required("Please enter message"),
              phone: yup.number().required("Please Enter your Number"),
            })}
            onSubmit={handleSubmit}
          >
            <Form>
              <div>
                {/* <div className="grid md:grid-cols-2 gap-x-8 p-6 h-75 backdrop-blur-md bg-gradient-to-r from-green-600 to-yellow-200"> */}
                <div className="grid md:grid-cols-2 gap-x-8 p-6 h-75 bg-green-400/40">
                  {/* Left section */}
                  <div className="grid gap-y-5">
                    <div>
                      {/* Name field */}
                      <Field
                        className="w-full p-4 pr-12 text-sm border-gray-200 rounded-lg shadow-sm"
                        type="name"
                        name="name"
                        placeholder="Enter your Name"
                        required
                      />
                      <p className="text-red-500 center font-normal">
                        <ErrorMessage name="name" />
                      </p>
                    </div>

                    {/* Email Field */}
                    <div>
                      <Field
                        className="w-full p-4 pr-12 text-sm border-gray-200 rounded-lg shadow-sm"
                        type="email"
                        name="email"
                        placeholder="Enter your Email"
                      />
                      <p className="text-red-500 center font-normal">
                        <ErrorMessage name="email" />
                      </p>
                    </div>

                    {/* Phone Number Field */}
                    <div>
                      <Field
                        className="mb-4 w-full p-4 pr-12 text-sm border-gray-200 rounded-lg shadow-sm"
                        type="number"
                        name="phone"
                        placeholder="Enter your number"
                      />
                      <p className="text-red-500 center font-normal">
                        <ErrorMessage name="phone" />
                      </p>
                    </div>
                  </div>
                  {/* Right Section */}
                  <div className="grid gap-y-4">
                    {/* Message Field */}
                    <div>
                      <Field
                        className="w-full bg-white rounded border border-gray-300 focus:ring-1 focus:ring-yellow-700 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                        type="text"
                        name="message"
                        placeholder="Enter your Message"
                      />
                      <p className="text-red-500 center font-normal">
                        <ErrorMessage name="message" />
                      </p>
                    </div>

                    {/* Submit button */}
                    <button className="block w-full px-12 py-3 border hover:border-black text-sm font-medium text-white bg-black rounded sm:w-auto active:text-opacity-75 hover:bg-transparent hover:text-black cursor-pointer">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </section>
    </div>
  );
};

export default Contact;
