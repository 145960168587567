import React from "react";
import { useState } from "react";
import { Link } from "react-scroll";
import "./Navigation.css";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [navbar, setNavbar] = useState(false); //navbar change on scroll
  const navBackground = () => {
    if (window.scrollY >= 650) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", navBackground);
  return (
    <>
      <div
        className={
          navbar
            ? "navBg sticky top-0 z-50 h-[12vh]  duration-500"
            : "bg-[#000000] text-white sticky top-0 z-50 h-[12vh] duration-500"
        }
      >
        <div class="z-10 px-4 py-3 sm:max-w-full h-full md:max-w-full lg:max-w-screen-full  md:px-24 lg:px-8 sticky top-0">
          <div class="flex items-center h-full justify-center">
            {/* LOGO is here */}
            <div className="w-40 h-25">
              <img
                src={process.env.PUBLIC_URL + "/images/logoBgRemove.png"}
                alt="vjLogo"
              />
            </div>
            <div class="flex items-center mx-auto mr-12">
              <ul class="items-center hidden space-x-8 lg:flex navFont">
                <li>
                  <Link
                    to="hero"
                    smooth={true}
                    spy={true}
                    offset={-90}
                    activeClassName="active"
                    className="navHover tracking-widest"
                  >
                    HOME
                  </Link>
                </li>
                <li>
                  <Link
                    to="who"
                    smooth={true}
                    spy={true}
                    offset={-70}
                    activeClassName="active"
                    className="navHover  tracking-widest"
                  >
                    WHO
                  </Link>
                </li>

                <li>
                  <Link
                    to="what"
                    smooth={true}
                    spy={true}
                    offset={-78}
                    activeClassName="active"
                    className="navHover  tracking-widest"
                  >
                    WHAT
                  </Link>
                </li>
                <li>
                  <Link
                    to="how"
                    smooth={true}
                    spy={true}
                    offset={-100}
                    activeClassName="active"
                    className="navHover  tracking-widest"
                  >
                    HOW
                  </Link>
                </li>
                <li>
                  <Link
                    to="portfolio"
                    smooth={true}
                    spy={true}
                    offset={-89}
                    activeClassName="active"
                    className="navHover tracking-widest uppercase"
                  >
                    Portfolio
                  </Link>
                </li>
                <li>
                  <Link
                    to="contact"
                    smooth={true}
                    spy={true}
                    offset={-89}
                    activeClassName="active"
                    className="navHover tracking-widest"
                  >
                    CONTACT
                  </Link>
                </li>
              </ul>
            </div>

            <div class="lg:hidden">
              <button
                aria-label="Open Menu"
                title="Open Menu"
                className="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50"
                onClick={() => setIsMenuOpen(true)}
              >
                <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
                  <path
                    fill="white"
                    d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
                  />
                  <path
                    fill="white"
                    d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
                  />
                  <path
                    fill="white"
                    d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
                  />
                </svg>
              </button>
              {isMenuOpen && (
                <div class="absolute -top-1 left-0 w-full z-10">
                  <div class="p-4 backdrop-blur-sm bg-white/10 border rounded shadow-sm">
                    <div class="flex items-center justify-between mb-4">
                      {/* Inside Side Bar */}
                      
                      <div>
                        {/* close button */}
                        <button
                          aria-label="Close Menu"
                          title="Close Menu"
                          class="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                          onClick={() => setIsMenuOpen(false)}
                        >
                          <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
                            <path
                              fill="currentColor"
                              d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <nav>
                      <ul class="space-y-4">
                        <li>
                          <Link
                            to="hero"
                            smooth={true}
                            class="tracking-wide font-bold text-black hover:text-yellow-400"
                            onClick={() => setIsMenuOpen(false)}
                          >
                            HOME
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="who"
                            smooth={true}
                            class="tracking-wide font-bold text-black hover:text-yellow-400"
                            onClick={() => setIsMenuOpen(false)}
                          >
                            WHO
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="what"
                            smooth={true}
                            class="tracking-wide font-bold text-black hover:text-yellow-400"
                            onClick={() => setIsMenuOpen(false)}
                          >
                            WHAT
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="how"
                            smooth={true}
                            class="tracking-wide font-bold text-black hover:text-yellow-400"
                            onClick={() => setIsMenuOpen(false)}
                          >
                            HOW
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="contact"
                            smooth={true}
                            class="tracking-wide font-bold text-black hover:text-yellow-400"
                            onClick={() => setIsMenuOpen(false)}
                          >
                            CONTACT
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
