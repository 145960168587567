import React from "react";
// import axios from 'axios';
import Aos from "aos";
import "aos/dist/aos.css";
import "./who.css";
import { Link } from "react-scroll";

const Who = () => {
  Aos.init({ duration: 1000 });
  return (
    <div className="z-50" id="who">
        <div class="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="grid grid-cols-1 gap-8 lg:gap-16 lg:grid-cols-2">
            <div
              class="drop-shadow-2xl relative h-64 overflow-hidden rounded-lg sm:h-80 lg:h-full lg:order-last

"
            >
              <img
                class="absolute inset-0 object-contain w-full h-full"
                src={process.env.PUBLIC_URL + "/images/ceo3.png"}
                data-aos="zoom-in-down"
                data-aos-offset="450"
                alt="Ceo_image"
              />
            </div>

            <div class="lg:py-24">
              <div className="flex flex-row gap-x-2">
                <h2 class="text-3xl font-bold sm:text-4xl uppercase tracking-wide">
                  VJ INVESTMENT
                </h2>
              </div>

              <p class="mt-4 text-gray-600">
                Vj investment company is established with a vision to choose the
                best returns company on the basis of its own research of
                validity based on demographics,trends,environment, technological
                advancements,human needs and lifestyle.
              </p>

              {/* Button Here */}
              <Link
                    to="contact"
                    smooth={true}
                    offset={-78}
                    className="cursor-pointer mt-5 relative inline-flex items-center px-12 py-3 overflow-hidden text-lg font-medium text-green-600 border-2 border-green-600 rounded-md hover:text-white group hover:bg-gray-50"
              >
                <span className="absolute left-0 block w-full h-0 transition-all bg-green-600 opacity-100 group-hover:h-full top-1/2 group-hover:top-0 duration-400 ease"></span>
                <span className="absolute right-0 flex items-center justify-start w-10 h-10 duration-300 transform translate-x-full group-hover:translate-x-0 ease">
                  <svg
                    className="w-5 h-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                    ></path>
                  </svg>
                </span>
                <span class="relative capitalize">Fulfil your Dream</span>
              </Link>
            </div>
          </div>
        </div>
    </div>
  );
};

export default Who;
